<template>
  <b-card :class="{'fixed-cart': windowWidth >= 1280 && isChecked}">
    <div
      class="cart-wrap"
    >
      <div class="wrap-section">
        <div class="cart-header">
          <span class="header-symbol">
            <feather-icon
              icon="ShoppingCartIcon"
            />
          </span>
          <span class="header-name">
            Mini Cart
          </span>
          <b-badge
            class="header-count badge-glow"
            variant="primary"
            pill
          >
            {{ cartItems.length }}
          </b-badge>
          <span
            v-if="windowWidth >= 1280"
            class="header-fixed"
          >
          </span>
        </div>
        <template>
          <div style="display: flex; justify-content: space-between; align-items: center;">
            <p v-if="selectedMiniGame === 'namedPowerball5'" style="margin: 0;">
              네임드파워볼(5분)
              <feather-icon icon="ClockIcon" /> 배팅마감: {{ remainingTimes.namedPowerball5 }}초
            </p>
            <p v-if="selectedMiniGame === 'namedPowerball3'" style="margin: 0;">
              네임드파워볼(3분)
              <feather-icon icon="ClockIcon" /> 배팅마감: {{ remainingTimes.namedPowerball3 }}초
            </p>
            <p v-if="selectedMiniGame === 'namedLadder5'" style="margin: 0;">
              네임드사다리(5분)
              <feather-icon icon="ClockIcon" /> 배팅마감: {{ remainingTimes.namedLadder5 }}초
            </p>
            <p v-if="selectedMiniGame === 'namedLadder3'" style="margin: 0;">
              네임드사다리(3분)
              <feather-icon icon="ClockIcon" /> 배팅마감: {{ remainingTimes.namedLadder3 }}초
            </p>
          </div>
        </template>
        <template v-if="!isLoading">
          <div
            class="cart-contents"
            style=""
          >
            <template>
              <div style="text-align: center; color: orange; margin-bottom: 0.4rem;">파워볼</div>
              <b-row>
                <b-col class="d-inline-flex justify-content-between" style="margin-bottom: 0.4rem">
                  <b-button
                    id="btn1"
                    v-ripple.400="'rgba(255, 159, 67, 0.15)'"
                    style="width: 100%"
                    :class="{ active: activeButton === 'btn1' }"
                    variant="outline-secondary"
                    @click="toggle('btn1')">
                    홀({{ selectedCartOdds[0].oddsHome1 }})
                  </b-button>
                  <b-button
                    id="btn2"
                    v-ripple.400="'rgba(255, 159, 67, 0.15)'"
                    style="width: 100%"
                    :class="{ active: activeButton === 'btn2' }"
                    variant="outline-secondary"
                    @click="toggle('btn2')">
                    짝({{ selectedCartOdds[0].oddsAway1 }})
                  </b-button>
                </b-col>
              </b-row>
              <b-row>
                <b-col class="d-inline-flex justify-content-between" style="margin-bottom: 0.4rem">
                  <b-button
                    id="btn3"
                    v-ripple.400="'rgba(255, 159, 67, 0.15)'"
                    style="width: 100%"
                    :class="{ active: activeButton === 'btn3' }"
                    variant="outline-secondary"
                    @click="toggle('btn3')">
                    언더({{ selectedCartOdds[1].oddsHome2 }})
                  </b-button>
                  <b-button v-ripple.400="'rgba(255, 159, 67, 0.15)'" variant="outline-secondary">
                    4.5({{ selectedCartOdds[1].oddsDraw2 }})
                  </b-button>
                  <b-button
                    id="btn4"
                    v-ripple.400="'rgba(255, 159, 67, 0.15)'"
                    style="width: 100%"
                    :class="{ active: activeButton === 'btn4' }"
                    variant="outline-secondary"
                    @click="toggle('btn4')">
                    오버({{ selectedCartOdds[1].oddsAway2 }})
                  </b-button>
                </b-col>
              </b-row>

              <div style="text-align: center; color: orange; margin-bottom: 0.4rem;">일반볼</div>
              <b-row>
                <b-col class="d-inline-flex justify-content-between" style="margin-bottom: 0.4rem">
                  <b-button
                    id="btn5"
                    v-ripple.400="'rgba(255, 159, 67, 0.15)'"
                    style="width: 100%"
                    :class="{ active: activeButton === 'btn5' }"
                    variant="outline-secondary"
                    @click="toggle('btn5')">
                    홀({{ selectedCartOdds[2].oddsHome3 }})
                  </b-button>
                  <b-button
                    id="btn6"
                    v-ripple.400="'rgba(255, 159, 67, 0.15)'"
                    style="width: 100%"
                    :class="{ active: activeButton === 'btn6' }"
                    variant="outline-secondary"
                    @click="toggle('btn6')">
                    짝({{ selectedCartOdds[2].oddsAway3 }})
                  </b-button>
                </b-col>
              </b-row>
              <b-row>
                <b-col class="d-inline-flex justify-content-between" style="margin-bottom: 0.4rem">
                  <b-button
                    id="btn7"
                    v-ripple.400="'rgba(255, 159, 67, 0.15)'"
                    style="width: 100%"
                    :class="{ active: activeButton === 'btn7' }"
                    variant="outline-secondary"
                    @click="toggle('btn7')">
                    언더({{ selectedCartOdds[3].oddsHome4 }})
                  </b-button>
                  <b-button v-ripple.400="'rgba(255, 159, 67, 0.15)'" variant="outline-secondary">
                    4.5({{ selectedCartOdds[3].oddsDraw4 }})
                  </b-button>
                  <b-button
                    id="btn8"
                    v-ripple.400="'rgba(255, 159, 67, 0.15)'"
                    style="width: 100%"
                    :class="{ active: activeButton === 'btn8' }"
                    variant="outline-secondary"
                    @click="toggle('btn8')">
                    오버({{ selectedCartOdds[3].oddsAway4 }})
                  </b-button>
                </b-col>
              </b-row>
              <b-row>
                <b-col class="d-inline-flex justify-content-between" style="margin-bottom: 0.4rem">
                  <b-button
                    id="btn9"
                    v-ripple.400="'rgba(255, 159, 67, 0.15)'"
                    style="width: 100%"
                    :class="{ active: activeButton === 'btn9' }"
                    variant="outline-secondary"
                    @click="toggle('btn9')">
                    소({{ selectedCartOdds[4].oddsHome5 }})
                  </b-button>
                  <b-button
                    id="btn10"
                    v-ripple.400="'rgba(255, 159, 67, 0.15)'"
                    style="width: 100%"
                    :class="{ active: activeButton === 'btn10' }"
                    variant="outline-secondary"
                    @click="toggle('btn10')">
                    중({{ selectedCartOdds[4].oddsDraw5 }})
                  </b-button>
                  <b-button
                    id="btn11"
                    v-ripple.400="'rgba(255, 159, 67, 0.15)'"
                    style="width: 100%"
                    :class="{ active: activeButton === 'btn11' }"
                    variant="outline-secondary"
                    @click="toggle('btn11')">
                    대({{ selectedCartOdds[4].oddsAway5 }})
                  </b-button>
                </b-col>
              </b-row>

              <div style="text-align: center; color: orange; margin-bottom: 0.4rem;">일반볼 [홀오버/홀언더]</div>
              <b-row>
                <b-col class="d-inline-flex justify-content-between" style="margin-bottom: 0.4rem">
                  <b-button
                    id="btn12"
                    v-ripple.400="'rgba(255, 159, 67, 0.15)'"
                    style="width: 100%"
                    :class="{ active: activeButton === 'btn12' }"
                    variant="outline-secondary"
                    @click="toggle('btn12')">
                    홀언더({{ selectedCartOdds[5].oddsHome6 }})
                  </b-button>
                  <b-button
                    id="btn13"
                    v-ripple.400="'rgba(255, 159, 67, 0.15)'"
                    style="width: 100%"
                    :class="{ active: activeButton === 'btn13' }"
                    variant="outline-secondary"
                    @click="toggle('btn13')">
                    홀오버({{ selectedCartOdds[5].oddsAway6 }})
                  </b-button>
                </b-col>
              </b-row>

              <div style="text-align: center; color: orange; margin-bottom: 0.4rem;">일반볼 [짝오버/짝언더]</div>
              <b-row>
                <b-col class="d-inline-flex justify-content-between" style="margin-bottom: 0.4rem">
                  <b-button
                    id="btn14"
                    v-ripple.400="'rgba(255, 159, 67, 0.15)'"
                    style="width: 100%"
                    :class="{ active: activeButton === 'btn14' }"
                    variant="outline-secondary"
                    @click="toggle('btn14')">
                    짝언더({{ selectedCartOdds[6].oddsHome7 }})
                  </b-button>
                  <b-button
                    id="btn15"
                    v-ripple.400="'rgba(255, 159, 67, 0.15)'"
                    style="width: 100%"
                    :class="{ active: activeButton === 'btn15' }"
                    variant="outline-secondary"
                    @click="toggle('btn15')">
                    짝오버({{ selectedCartOdds[6].oddsAway7 }})
                  </b-button>
                </b-col>
              </b-row>
            </template>
          </div>
          <div class="cart-contents">
            <div class="calculator">
              <b-button
                v-for="(button, bIndex) of calButtons"
                :key="bIndex"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-secondary"
                @click="handleBetButton(button)"
              >
                {{ button.text }}
              </b-button>
            </div>
            <b-form-input
              v-model="desiredBet"
              type="number"
              style="color: yellow; text-align: right; margin-bottom: 0.5rem; border-color: rgba(236,229,216,0.42)"
              @click="selectAll"
              @change="changeCustomCash"
            >
            </b-form-input>
            <div class="bet">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                @click="confirmBet"
              >
                배팅하기
              </b-button>
            </div>
          </div>
        </template>
        <template
          v-else
        >
          <div class="d-flex justify-content-center mt-3 mb-2">
            <b-spinner
              variant="primary"
              label="Text Centered"
            />
          </div>
        </template>
      </div>
      <div class="wrap-section">
        <div class="cart-header">
          <span class="header-symbol">
            <feather-icon
              icon="LayersIcon"
            />
          </span>
          <span class="header-name">
            Price Details
          </span>
        </div>
        <div class="cart-contents">
          <div class="order">
            <ul>
              <li><span>보유금액</span><span class="text-success">&#8361;{{ Number(userCash).toLocaleString() }}</span></li>
              <li><span>최소배팅금</span><span>{{ betSettings.minBetAmount | formatCurrency('&#8805;') }}</span></li>
              <li><span>최대배팅금</span><span>{{ betSettings.maxBetAmount | formatCurrency('&#8805;') }}</span></li>
              <li><span>적중상한금</span><span>{{ betSettings.maxWinAmount | formatCurrency('&#8805;') }}</span></li>
              <li><span>예상배당률</span><span class="text-success">x{{ Number(oddsRate).toFixed(2) }}</span></li>
            </ul>
            <ul>
              <li><span>배팅금액</span><span class="font-weight-bolder" style="color: yellow">{{ Number(desiredBet).toLocaleString() }}</span></li>
              <li><span>예상배당금</span><span class="font-weight-bolder">{{ Number(expectedOdds).toLocaleString() }}</span></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </b-card>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BButton,
  BBadge,
  BFormCheckbox,
  BSpinner,
  BFormInput,
  VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Swal from 'sweetalert2'

export default {
  name: 'MiniCart',
  components: {
    // BSV
    BRow,
    BCol,
    BCard,
    BButton,
    BBadge,
    BFormCheckbox,
    BSpinner,
    BFormInput,
    VuePerfectScrollbar,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  filters: {
    formatCurrency(value, symbol) {
      let str = ''
      str += symbol ? `${symbol} ` : ''
      str += ' ￦'
      str += value
      // str += `${Number(value).toLocaleString()}`
      return str
    },
  },
  props: {
    cartItems: {
      type: Array,
      required: true,
    },
    filteredSetMenu: {
      type: Array,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
    windowWidth: {
      type: Number,
      required: true,
    },
    remainingTimes: {
      type: Object,
      required: true,
    },
    selectedMiniGame: {
      type: String,
      required: true,
    },
    filteredOddsForButtons: {
      type: Array,
      required: true,
    },
    filteredSetBasic: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      componentName: this.$options.name,

      isChecked: true,

      userCash: 0,
      oddsRate: 0,
      expectedOdds: 0,
      desiredBet: 0,

      calButtons: [
        { text: '1만', action: 'bet', value: 10000, type: 'increase' },
        { text: '5만', action: 'bet', value: 50000, type: 'increase' },
        { text: '10만', action: 'bet', value: 100000, type: 'increase' },
        { text: '25만', action: 'bet', value: 250000, type: 'increase' },
        { text: '50만', action: 'bet', value: 500000, type: 'increase' },
        { text: '100만', action: 'bet', value: 1000000, type: 'increase' },
        { text: 'HALF', action: 'bet', value: 0.5, type: 'asset' },
        { text: 'MAX', action: 'bet', value: 1, type: 'asset' },
        { text: 'RESET', action: 'reset-bet-cash' },
      ],

      miniCartOdds: [
        { oddsHome1: null, oddsDraw1: null, oddsAway1: null },
        { oddsHome2: null, oddsDraw2: null, oddsAway2: null },
        { oddsHome3: null, oddsDraw3: null, oddsAway3: null },
        { oddsHome4: null, oddsDraw4: null, oddsAway4: null },
        { oddsHome5: null, oddsDraw5: null, oddsAway5: null },
        { oddsHome6: null, oddsDraw6: null, oddsAway6: null },
        { oddsHome7: null, oddsDraw7: null, oddsAway7: null },
      ],

      // vuePerfectScrollbar
      perfectScrollbarSettings: {
        wheelSpeed: 0.5, // 마우스 휠로 스크롤할 때의 속도 조절 (0과 1 사이의 값)
        wheelPropagation: true, // 마우스 휠로 스크롤할 때 스크롤 이벤트가 부모 요소로 전파되는지 여부 결정 (true 또는 false)
        minScrollbarLength: 20, // 스크롤바의 최소 길이 설정 (픽셀 단위)
      },

      activeButton: null, // 활성화된 버튼 ID를 저장
      // isActiveBtn1: false,
      // isActiveBtn2: false,
      // isActiveBtn3: false,
      // isActiveBtn4: false,
      // isActiveBtn5: false,
      // isActiveBtn6: false,
    }
  },
  computed: {
    betSettings() {
      return {
        minBetAmount: this.filteredSetMenu.length > 0
          ? Number(this.filteredSetMenu[0].minBet).toLocaleString()
          : Number(1000000).toLocaleString(),
        maxBetAmount: this.filteredSetMenu.length > 0
          ? Number(this.filteredSetMenu[0].maxBet).toLocaleString()
          : Number(10000000).toLocaleString(),
        maxWinAmount: this.filteredSetMenu.length > 0
          ? Number(this.filteredSetMenu[0].maxWin).toLocaleString()
          : Number(30000000).toLocaleString(),
      }
    },
    sortedCartItems() {
      // Bonus 항목들을 분리하고, 그 외의 항목들은 스포츠 종류별로 분류하여 정렬
      const bonusItems = []
      const sportsGroupedItems = {}
      const orderedSportsGroupedItems = {}

      this.cartItems.forEach(item => {
        if (item.gameType === 'Bonus') {
          bonusItems.push(item)
        } else {
          if (!sportsGroupedItems[item.sports]) {
            sportsGroupedItems[item.sports] = []
          }
          sportsGroupedItems[item.sports].push(item)
        }
      })

      // 스포츠 종류별로 내림차순 정렬
      Object.keys(sportsGroupedItems).forEach(sport => {
        sportsGroupedItems[sport].sort((a, b) => new Date(b.gameDate) - new Date(a.gameDate))
      })

      // 원하는 순서 정의
      const order = ['soccer', 'basketball', 'baseball', 'tennis', 'volleyball', 'football', 'hockey', 'esports']

      // 원하는 순서대로 sportsGroupedItems 재정렬
      order.forEach(sport => {
        if (sportsGroupedItems[sport]) {
          orderedSportsGroupedItems[sport] = sportsGroupedItems[sport]
        }
      })

      // 결과를 합칩니다. bonusItems가 앞에 오도록 합니다.
      return {
        bonusItems,
        sportsGroupedItems: orderedSportsGroupedItems,
      }
    },
    selectedCartOdds() {
      const miniCartOddsCopy = [...this.miniCartOdds]
      const oddsTypes = [
        'Custom Odds1',
        'Custom Odds2',
        'Custom Odds3',
        'Custom Odds4',
        'Custom Odds5',
        'Custom Odds6',
        'Custom Odds7',
      ]
      const keys = ['Home', 'Draw', 'Away']

      // itemId 설정
      let itemId
      switch (this.selectedMiniGame) {
        case 'namedPowerball5':
          itemId = '001'
          break
        case 'namedPowerball3':
          itemId = '002'
          break
        case 'namedLadder5':
          itemId = '003'
          break
        case 'namedLadder3':
          itemId = '004'
          break
        default:
          return miniCartOddsCopy
      }

      // 데이터 매핑
      oddsTypes.forEach((type, typeIndex) => {
        const data = this.filteredOddsForButtons.find(item => item.itemId === itemId && item.oddsType === type)
        if (data && miniCartOddsCopy[typeIndex]) {
          keys.forEach(key => {
            miniCartOddsCopy[typeIndex][`odds${key}${typeIndex + 1}`] = data[`oddsAdd${key}`]
          })
        }
      })

      return miniCartOddsCopy
    },
  },
  watch: {
    cartItems() {
      this.handleCartCalc()
    },
  },
  created() {
    // console.log('created()', this.componentName)
    this.init()
  },
  methods: {
    init() {
      // console.log('init()', this.componentName)
      // this.userData = this.$store.getters['authStore/user']
      // this.userInfo.cash = this.userData.cash
      this.userCash = JSON.parse(localStorage.getItem('userData')).cash
      this.handleCartCalc()
    },

    handleBetButton(button) {
      // 배팅 아이템없으면 토스트 메시지 띄우고 배팅 안되게하기
      if (this.cartItems.length === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: '1개 이상의 아이템을 추가해주세요',
            icon: 'InfoIcon',
            variant: 'warning',
          },
        })
        return
      }

      const betting = button
      switch (betting.action) {
        case 'bet':
          if (betting.type === 'asset') {
            // this.desiredBet = this.userCash * betting.value
            this.desiredBet = Number(this.filteredSetMenu[0].maxBet) * betting.value
          } else if (betting.type === 'increase') {
            this.desiredBet += betting.value
          }
          break
        case 'reset-cart':
          this.$emit('reset-cart')
          break
        case 'reset-bet-cash':
          this.desiredBet = 0
          break
        default:
          break
      }
      this.handleCartCalc()
    },
    handleCartCalc() {
      // 카트가 비었을 때, 배팅액을 0으로 설정합니다.
      if (this.cartItems.length === 0) {
        this.desiredBet = 0
      }

      this.oddsRate = this.cartItems.reduce((acc, cartItem) => acc * cartItem.odds, 1)
      this.expectedOdds = this.oddsRate * this.desiredBet
    },
    async confirmBet() {
      // console.log('filteredSetMenuSidebar:::', this.filteredSetMenu)
      if (Number(this.desiredBet) < Number(this.filteredSetMenu[0].minBet)) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: '최소배팅 금액을 확인해주세요',
            icon: 'InfoIcon',
            variant: 'info',
          },
        })
        return
      }
      if (Number(this.desiredBet) > Number(this.filteredSetMenu[0].maxBet)) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: '최대배팅 금액을 확인해주세요',
            icon: 'InfoIcon',
            variant: 'info',
          },
        })
        return
      }
      if (Number(this.expectedOdds) > Number(this.filteredSetMenu[0].maxWin)) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: '적중상한 금액을 확인해주세요',
            icon: 'InfoIcon',
            variant: 'info',
          },
        })
        return
      }
      // if (this.cartItems.length < this.filteredSetMenu[0].foldFrom) {
      //   const { foldFrom } = this.filteredSetMenu[0]
      //   this.$toast({
      //     component: ToastificationContent,
      //     props: {
      //       title: `최소배팅 폴더수는${foldFrom}폴더 입니다`,
      //       icon: 'InfoIcon',
      //       variant: 'info',
      //     },
      //   })
      //   return
      // }
      // if (this.cartItems.length > this.filteredSetMenu[0].foldTo) {
      //   const { foldTo } = this.filteredSetMenu[0]
      //   this.$toast({
      //     component: ToastificationContent,
      //     props: {
      //       title: `최대배팅 폴더수는${foldTo}폴더 입니다`,
      //       icon: 'InfoIcon',
      //       variant: 'info',
      //     },
      //   })
      //   return
      // }
      this.betConfirm()
    },
    // success
    betConfirm() {
      Swal.fire({
        title: '베팅하시겠습니까??',
        text: Number(this.desiredBet).toLocaleString(),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes!',
        background: 'rgb(3,11,37)',
        customClass: {
          cancelButton: 'btn btn-dark',
          confirmButton: 'btn btn-primary mr-1',
        },
      }).then(result => {
        if (result.isConfirmed) {
          this.$emit('create-bet', {
            cartItems: this.cartItems,
            betCash: this.desiredBet,
            betIp: localStorage.getItem('ip'),
          })
          this.betSuccess()
        }
      })
    },
    // success
    betSuccess() {
      Swal.fire({
        title: 'Betting Success',
        text: Number(this.desiredBet).toLocaleString(),
        icon: 'success',
        timer: 5000,
        background: 'rgb(3,11,37)',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
      // this.createBetHistory(this.desiredBet)
      // this.createBetHistory({
      //   cartItems: this.cartItems,
      //   betCash: this.desiredBet,
      //   betIp: localStorage.getItem('ip'),
      // })
      // this.$emit('createBet', {
      //   cartItems: this.cartItems,
      //   betCash: this.desiredBet,
      //   betIp: localStorage.getItem('ip'),
      // })
      this.$emit('reset-cart')
    },
    selectAll(event) {
      event.target.select()
    },
    async changeCustomCash(event) {
      // console.log(event)
      this.desiredBet = Number(event)
    },
    toggle(btn) {
      // 같은 버튼을 클릭하면 비활성화, 다른 버튼을 클릭하면 활성화
      this.activeButton = this.activeButton === btn ? null : btn
      console.log('@@', this.activeButton)
    },
  },
}
</script>

<style scoped>
/* scoped 스타일을 사용하여 현재 컴포넌트에만 적용됩니다. */

.card-body {
  padding: 0.2rem;
}

.fixed-cart {
  position: fixed;
  width: calc(285px - 5px); /* cart너비(285px) - 왼쪽너비(5px) */
}

.cart-wrap * {
  color: white;
}

.cart-wrap {
  padding: 15px;
}

.cart-wrap .wrap-section .cart-header {
  display: flex;
  align-items: center;
  padding: 0.5rem 2px;
  margin-bottom: 0.5rem;
  border-bottom: 1px solid #3b4253
}

.cart-wrap .wrap-section .cart-header .header-symbol svg  {
  height: 15px;
  width: 15px;
  margin-right: 5px;
}

.cart-wrap .wrap-section .cart-header .header-count {
  margin-left: 0.7rem;
}

.cart-wrap .wrap-section .cart-header .header-fixed {
  margin-left: auto; /* 오른쪽 배치 */
}

/* cart-contents > items */
.cart-wrap .wrap-section .cart-contents .ps-container {
  margin-right: -10px;
  padding-right: 10px;
}

.cart-wrap .wrap-section .cart-contents {
  padding: 0.5rem 2px;
  margin-bottom: 0.5rem;
  border-bottom: 1px solid #3b4253
}

.cart-wrap .wrap-section .cart-contents:last-child {
  border-bottom: 0px solid #3b4253
}

.cart-wrap .wrap-section .cart-contents .sport-divider {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 3px;
  margin-bottom: 5px;
}

.cart-wrap .wrap-section .cart-contents .divider-line {
  height: 1px;
  width: 40%;
  background: #ccc;
  /* margin: 0 10px; */
}

.cart-wrap .wrap-section .cart-contents .sport-name {
  /* font-weight: bold; */
  font-size: 1.0em;
  margin: 0 5px;
}

.cart-wrap .wrap-section .cart-contents .items button {
  display: flex; /* flex 컨테이너 설정 */
  width:100%;
  padding: 0.5rem 0.7rem !important;
  margin-bottom: 0.1rem;
  justify-content: space-between; /* 자식 요소들 사이의 간격을 균등하게 설정 */
  align-items: center;
}

.cart-wrap .wrap-section .cart-contents .items button * {
  color: rgb(255, 198, 139) !important;
}

.cart-wrap .wrap-section .cart-contents .items button .checked * {
  color: #54FF10 !important;
}

.cart-wrap .wrap-section .cart-contents .items button > div:first-child {
  /* margin-right: 0.3rem; */
  flex: 0 0 60%;
  max-width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.cart-wrap1 .wrap-section .cart-contents .items button > div:first-child svg {
  color: #54FF10 !important;
  font-size: 13px;
}

.cart-wrap .wrap-section .cart-contents .items button > div:last-child {
  flex: 0 0 40%;
  max-width: 40%;
  font-weight: bolder;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

.cart-wrap .wrap-section .cart-contents .items button > div:first-child > div,
.cart-wrap .wrap-section .cart-contents .items button > div:last-child > div {
  padding: 0.3rem 0rem;
}

/* point up&down */
.btn-wrap:has(.point) button{
  position: relative;
}

.btn-wrap:has(.point) button .point::after {
  content: "";
  position: absolute;
  right: 3px;  /* 버튼과 화살표 간의 간격 조정 */
  width: 0;
  height: 0;
  border-style: solid;
  transition: opacity 0.5s; /* 0.5초 동안 서서히 사라지도록 */
}

.btn-wrap:has(.point) button.point-up .point::after {
  top: 3px;  /* 버튼과 화살표 간의 간격 조정 */
  border-width: 0px 7px 7px 0px; /* 상, 우, 하, 좌 */
  transform: rotate(180deg); /* 위쪽 화살표를 나타내기 위해 45도 회전 */
  border-color: transparent transparent #ff0000 transparent; /* 화살표 색상 */
  animation: disappear 2.5s forwards; /* 1초 동안 애니메이션이 실행되고 끝난 후 사라지도록 */
}

.btn-wrap:has(.point) button.point-down .point::after {
  bottom: 3px;
  border-width: 0px 7px 7px 0px; /* 상, 우, 하, 좌 */
  transform: rotate(270deg); /* 아래쪽 화살표를 나타내기 위해 -45도 회전 */
  border-color: transparent transparent #0000ff transparent; /* 화살표 색상 */
  animation: disappear 2.5s forwards; /* 1초 동안 애니메이션이 실행되고 끝난 후 사라지도록 */
}

.btn-wrap:has(.point) button:not(.point-up, .point-down) .point::after {
  content: none;
}

.cart-wrap .wrap-section .cart-contents .items .items-container {
  padding-right: 10px; /* 원하는 거리만큼 값을 조절하세요 */
}

/* cart-contents > calculator */
.cart-wrap .wrap-section .cart-contents .calculator {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0.1rem;
  padding-bottom: 0.3rem;
}
.cart-wrap .wrap-section .cart-contents .calculator button {
  padding: 0.7rem 1rem;
}

/* cart-contents > bet */
.cart-wrap .wrap-section .cart-contents .bet button {
  width: 100%;
}

.cart-wrap .order ul {
  list-style-type: none; /* 리스트 아이템 앞의 점을 제거 */
  padding-left: 0; /* 왼쪽 패딩 제거 */
  margin-left: 0; /* 왼쪽 마진 제거 */
}

.cart-wrap .order ul > li {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}

/* 활성화된 버튼 스타일 정의 */
.active {
  color: white !important;
  background: linear-gradient(to right, #bf53b1, #d369a4, #ff7c6e, #bf53b1);
  background-size: 400% 100% !important; /* 배경 크기를 세 배로 설정 */
  animation: gradient-slide 5s linear 1 forwards !important; /* 애니메이션 속성 적용 */
  border-color: orange !important;
}
</style>
<style>
</style>
