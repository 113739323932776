import { render, staticRenderFns } from "./Mini-Cart.vue?vue&type=template&id=5866f787&scoped=true&"
import script from "./Mini-Cart.vue?vue&type=script&lang=js&"
export * from "./Mini-Cart.vue?vue&type=script&lang=js&"
import style0 from "./Mini-Cart.vue?vue&type=style&index=0&id=5866f787&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5866f787",
  null
  
)

export default component.exports